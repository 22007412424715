import React from 'react'
import {Helmet} from "react-helmet";

const MetaTitleDesc = ({ metatitle, metadesc }) => {
    let currentUrl = '';
    if (typeof window !== 'undefined') {
        currentUrl = window.location.href;
    }



    return (
        <Helmet>
            <title>{metatitle}</title>
            {currentUrl && <link rel="canonical" href={currentUrl} />}
            <meta name="description" content={metadesc} />
        </Helmet>
    )
}

export default MetaTitleDesc
