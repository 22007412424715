import React from 'react'
import Header from './Header'
import Routers from '../router/Routers'
import Footer from './Footer'
import { useLocation } from 'react-router-dom';

const Layout = () => {
  const location = useLocation();
  const isLogoDesignServicesPage = location.pathname === '/logo-design-services';
  return (
    <>
    {!isLogoDesignServicesPage && <Header />}
     <div className="bg-wrap">
     <div className='position-relative'>
        <Routers/>
     </div></div>
     {!isLogoDesignServicesPage && <Footer />}
    </>
  )
}

export default Layout
