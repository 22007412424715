import React from 'react'
// import { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination, Autoplay,Navigation } from 'swiper/modules';
import s1 from '../Assets/images/s1.webp'
import s3 from '../Assets/images/s3.webp'
import b1 from '../Assets/images/b1.webp'
import b3 from '../Assets/images/b3.webp'
import b2 from '../Assets/images/b2.webp'


import { Container } from 'react-bootstrap';
const Slider = () => {
  return (
    <>
      <Container className='pt--90'>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        navigation={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          0: {
            slidesPerView: 3,

          },
          768: {
            slidesPerView: 4,

          },
          991: {
            slidesPerView: 4,

          },
        }}
        modules={[Pagination, Autoplay ,Navigation]}
        className="mySwiper pt-3"
      >
        <SwiperSlide><img src={s1} alt="" /></SwiperSlide>
        <SwiperSlide><img src={b1} alt="" /></SwiperSlide>
        <SwiperSlide><img src={s3} alt="" /></SwiperSlide>
        <SwiperSlide><img src={b2} alt="" /></SwiperSlide>
        <SwiperSlide><img src={b3} alt="" /></SwiperSlide>
        <SwiperSlide><img src={s1} alt="" /></SwiperSlide>
        <SwiperSlide><img src={b1} alt="" /></SwiperSlide>
        <SwiperSlide><img src={s3} alt="" /></SwiperSlide>
        <SwiperSlide><img src={b2} alt="" /></SwiperSlide>
        <SwiperSlide><img src={b3} alt="" /></SwiperSlide>
        
      </Swiper>
     


      </Container>
    </>
  )
}

export default Slider
