import { initializeApp }  from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA-HCSLH8bUYvXSZKASiTxhbJjFinVG85A",
  authDomain: "fortunelogos-7b62a.firebaseapp.com",
  projectId: "fortunelogos-7b62a",
  storageBucket: "fortunelogos-7b62a.appspot.com",
  messagingSenderId: "287270129743",
  appId: "1:287270129743:web:6000d3541625e31a095a3a",
  measurementId: "G-SL642VL3RG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };

