import React from 'react';
import './App.css';
import Layout from './layout/Layout';
import ScrollToTop from './components/ScrollToTop';

function App() {
  
  return (
    <>
    <ScrollToTop/>
   <Layout/>
    </>
  );
}

export default App;
