import React from 'react'

const NewTitleDesc = ({title,heading,subheading,paragraph1,paragraph2}) => {
  return (
    <>
    <div className="container">
    <div className="row">
            <div className="col-lg-12">
                <div className="text-center text-white">
                    <h2 className="heading-title text-white">{title}</h2>
                    <h1 className="heading heading-h3 text-white rslide">
                        {heading}
                    </h1>
                    <h6 className="heading heading-h6 text-white letter-spacing-1 pb-lg-3 pb-md-3">
                        {subheading}
                    </h6>
                    <p className="text-start fs-17 pt-lg-0 pt-md-0 pt-4">{paragraph1}</p>
                    <p className="text-start fs-17">{paragraph2}</p>
                </div>
            </div>
        </div>
    </div>


      
    </>
  )
}

export default NewTitleDesc
