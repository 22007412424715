import React, { createContext, useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from './Firebase'; // Ensure you import your initialized firebase instance
import emailjs from '@emailjs/browser';

export const FormContext = createContext();

const EMAILJS_SERVICE_ID = 'service_1r2mp0m';
const EMAILJS_TEMPLATE_ID = 'template_z764t3l';
const EMAILJS_USER_ID = 'l1NkPyb-Fyv4OF13U';

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    sloganName: '',
    industry: '',
    keyword: '',
    emailAddress: '',
    number: '',
  });

  const saveFormData = async () => {
    try {
      const docRef = await addDoc(collection(db, 'formData'), formData);
      console.log('Document written with ID: ', docRef.id);
      sendEmail(); // Call function to send email after successful form submission
    } catch (error) {
      console.error('Error adding document: ', error);
      // Handle error scenario (e.g., show error message to user)
    }
  };

  const sendEmail = () => {
    emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, formData, EMAILJS_USER_ID)
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        // Handle error scenario (e.g., show error message to user)
      });
  };

  return (
    <FormContext.Provider value={{ formData, setFormData, saveFormData }}>
      {children}
    </FormContext.Provider>
  );
};
