import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Privacy from '../pages/Privacy';
import ScrollToTop from '../components/ScrollToTop';


const Routers = () => {
  useEffect(() => {
    // Start of LiveChat code
window._lc = window._lc || {};
window.__lc.license = 13034076;

(function (n, t, c) {
 function i(n) {
   return e._h ? e._h.apply(null, n) : e._q.push(n);
 }

 var e = {
   _q: [],
   _h: null,
   _v: '2.0',
   on: function () {
     i(['on', c.call(arguments)]);
   },
   once: function () {
     i(['once', c.call(arguments)]);
   },
   off: function () {
     i(['off', c.call(arguments)]);
   },
   get: function () {
     if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
     return i(['get', c.call(arguments)]);
   },
   call: function () {
     i(['call', c.call(arguments)]);
   },
   init: function () {
     var n = t.createElement('script');
     n.async = !0;
     n.type = 'text/javascript';
     n.src = 'https://cdn.livechatinc.com/tracking.js';
     t.head.appendChild(n);
   },
 };

 !n.__lc.asyncInit && e.init();
 n.LiveChatWidget = n.LiveChatWidget || e;
})(window, document, [].slice);
// End of LiveChat code
}, []);

const openLiveChat = () => {
   window.LC_API.open_chat_window();
};
  const [components, setComponents] = useState({
    Home: null,
    LogoBranding: null,
    Portfolio: null,
    Pricing: null,
    About: null,
    Work2: null,
    Steps1: null,
    Steps2: null,
    Steps3: null,
    Steps4: null,
    Steps5: null,
    Steps6: null,
    FinalStep: null,
    Checkout: null,
    Website: null,
    Mobile: null,
    Videopage: null,
    AmazonMarketing: null,
    BookPublisher: null,
    BookPublishing: null,
    LandingPage: null,
    Seo: null,
    Privacy: null,
  });

  useEffect(() => {
    const loadComponents = async () => {
      // Conditionally load components based on the current path
      const currentPath = window.location.pathname;

      if (currentPath === '/logo-design-services') {
        // Load only the LandingPage component
        const { default: LandingPage } = await import('../pages/LandingPage');
        setComponents(prev => ({ ...prev, LandingPage }));
      } else {
        // Load all required components for other paths
        const [
          { default: Home },
          { default: LogoBranding },
          { default: Portfolio },
          { default: Pricing },
          { default: About },
          { default: Work2 },
          { default: Steps1 },
          { default: Steps2 },
          { default: Steps3 },
          { default: Steps4 },
          { default: Steps5 },
          { default: Steps6 },
          { default: FinalStep },
          { default: Checkout },
          { default: Website },
          { default: Mobile },
          { default: Videopage },
          { default: AmazonMarketing },
          { default: Seo },
          { default: BookPublishing },
          { default: Privacy},
        ] = await Promise.all([
          import('../pages/Home'),
          import('../pages/LogoBranding'),
          import('../pages/Portfolio'),
          import('../pages/Pricing'),
          import('../pages/About'),
          import('../components/Work2'),
          import('../pages/Steps1'),
          import('../pages/Steps2'),
          import('../pages/Steps3'),
          import('../pages/Steps4'),
          import('../pages/Steps5'),
          import('../pages/Steps6'),
          import('../pages/FinalStep'),
          import('../pages/Checkout'),
          import('../pages/Website'),
          import('../pages/Mobile'),
          import('../pages/Videopage'),
          import('../pages/AmazonMarketing'),
          import('../pages/Seo'),
          import('../pages/BookPublishing'),
          import('../pages/Privacy'),
        ]);

        setComponents({
          Home,
          LogoBranding,
          Portfolio,
          Pricing,
          About,
          Work2,
          Steps1,
          Steps2,
          Steps3,
          Steps4,
          Steps5,
          Steps6,
          FinalStep,
          Checkout,
          Website,
          Mobile,
          Videopage,
          AmazonMarketing,
          BookPublishing,
          Seo,
          Privacy,
          LandingPage: null, // Ensure LandingPage is not loaded
        });
      }
    };

    loadComponents();
  }, []);

  const {
    Home,
    LogoBranding,
    Portfolio,
    Pricing,
    About,
    Work2,
    Steps1,
    Steps2,
    Steps3,
    Steps4,
    Steps5,
    Steps6,
    FinalStep,
    Checkout,
    Website,
    Mobile,
    Videopage,
    AmazonMarketing,
    BookPublishing,
    LandingPage,
    Seo,
  } = components;

  return (
    
      <Routes>
        {LandingPage ? (
          <Route path="/logo-design-services" element={<LandingPage openLiveChat={openLiveChat} />} />
        ) : (
          
          <>
          
            <Route path="/" element={Home ? <Home  openLiveChat={openLiveChat}/> : ''} />
            <Route path="/home" element={<Navigate to="/" openLiveChat={openLiveChat} />} />
            <Route path="/logobranding" element={LogoBranding ? <LogoBranding openLiveChat={openLiveChat} /> : ''} />
            <Route path="/portfolio" element={Portfolio ? <Portfolio openLiveChat={openLiveChat}  /> : ''} />
            <Route path="/pricing" element={Pricing ? <Pricing openLiveChat={openLiveChat}/> : ''} />
            <Route path="/about" element={About ? <About openLiveChat={openLiveChat} /> : ''} />
            <Route path="/work" element={Work2 ? <Work2 /> : ''} />
            <Route path="/website" element={Website ? <Website openLiveChat={openLiveChat} /> : ''} />
            <Route path="/mobile-application" element={Mobile ? <Mobile openLiveChat={openLiveChat} /> : ''} />
            <Route path="/video-animation" element={Videopage ? <Videopage  openLiveChat={openLiveChat}/> : ''} />
            <Route path="/privacy-policy" element={Privacy ? <Privacy  openLiveChat={openLiveChat}/> : ''} />
            <Route path="/book-publication" element={BookPublishing ? <BookPublishing openLiveChat={openLiveChat} /> : ''} />
            <Route path="/amazon-marketing" element={AmazonMarketing ? <AmazonMarketing openLiveChat={openLiveChat} /> : ''} />
            <Route path="/seo-sem-services-company-usa" element={Seo ? <Seo openLiveChat={openLiveChat} /> : ''} />
            <Route path="/brief/company" element={Steps1 ? <Steps1 /> : ''} />
            <Route path="/brief/slogan" element={Steps2 ? <Steps2 /> : ''} />
            <Route path="/brief/industry" element={Steps3 ? <Steps3 /> : ''} />
            <Route path="/brief/keyword" element={Steps4 ? <Steps4 /> : ''} />
            <Route path="/brief/email" element={Steps5 ? <Steps5 /> : ''} />
            <Route path="/brief/number" element={Steps6 ? <Steps6 /> : ''} />
            <Route path="/final-step" element={FinalStep ? <FinalStep  openLiveChat={openLiveChat}/> : ''} />
            <Route path="/checkout" element={Checkout ? <Checkout /> : ''} />
          </>
        )}
      </Routes>
  );
};

export default Routers;
