import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../Assets/images/2024-08-02 New Logo copy.webp'
// import {Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleNavItemClick = () => {
    setExpanded(false); // Collapse the menu after clicking a link
  };

  return (
    <>
      <header className="sticky-top w-100 d-lg-block d-none position-relative header123 bg-dark" style={{ height: "62px", background:"#rgb(129 129 129)" }}>
        <Navbar expand="lg" variant="dark" bg="dark">
          <Container>
            <Navbar.Brand as={Link} to="/">
              <div className="position-relative11">
              <img src={logo} className="img-fluid" style={{ height: "100px", width: "150px", top:"10%", position:"absolute" ,padding:"9px", background: "#212529", borderRadius:"30px"}} alt="Logo" />
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link as={Link} to="/" className="active fs-16 text-shadow">Home</Nav.Link>
                <NavDropdown
                  title="Services"
                  id="navbarDropdown"
                  className="fs-16 text-shadow custom-dropdown "style={{ fontWeight: "500" }}
                  show={dropdownOpen}
                  onMouseEnter={() => setDropdownOpen(true)}
                  onMouseLeave={() => setDropdownOpen(false)}
                >
                  <NavDropdown.Item as={Link} to="/logobranding">Logo & Branding</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/website">Website</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/mobile-application">Mobile Application</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/amazon-marketing">Amazon Marketing</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/book-publication">Book Publication</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/seo-sem-services-company-usa">Seo Service</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/portfolio" className="text-white fs-16 text-shadow">Portfolio</Nav.Link>
                <Nav.Link as={Link} to="/pricing" className="text-white fs-16 text-shadow">Pricing</Nav.Link>
                <Nav.Link as={Link} to="/about" className="text-white fs-16 text-shadow">About Us</Nav.Link>
                <Nav.Link href="/" className="fs-16 call-btn">
                  <i className="fa-solid fa-phone"></i><a href="tel:209-227-2277">&nbsp;209-227-2277</a>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>



      <header className="sticky-top w-100 d-block d-lg-none">
      <Navbar expanded={expanded} onToggle={() => setExpanded(!expanded)} expand="lg" variant="dark" bg="dark">
        <Container>
          <Navbar.Brand as={Link} to="/index.html">
            <img src={logo} className="img-fluid" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" onClick={handleNavItemClick}>
              <Nav.Link as={Link} to="/" className="active">Home</Nav.Link>
              <NavDropdown title="Services" id="navbarDropdown">
                <NavDropdown.Item as={Link} to="/logobranding">Logo & Branding</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/website">Website</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/mobile-application">Mobile Application</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/amazon-marketing">Amazon Marketing</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/book-publication">Book Publication</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/seo-sem-services-company-usa">Seo Service</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/portfolio" className="text-white">Portfolio</Nav.Link>
              <Nav.Link as={Link} to="/pricing" className="text-white">Pricing</Nav.Link>
              <Nav.Link as={Link} to="/about" className="text-white">About Us</Nav.Link>
              <Nav.Link href="/" className="text-white">
                <i className="fa-solid fa-phone"></i>&nbsp;929-322-1091
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>


    </>
  )
}

export default Header
