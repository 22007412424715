import{ React,useState,useEffect }from 'react'
import { Element} from 'react-scroll';
import Slider from '../components/Slider'
import NewTitleDesc from '../components/NewTitleDesc';


import MetaTitleDesc from '../MetaTitleDesc';

const Privacy = ({ openLiveChat}) => {
    const backgroundImageStyle = {
        background: 'url(../Assets/images/bg-privacy.webp)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };
      const colorIntervals = [
        { height: 0, gradient: 'linear-gradient(180deg, #FF426E, #FBB43E)' },
        { height: 900, gradient: 'linear-gradient(180deg, #FBB43E, #C3D63E)' },
        { height: 1500, gradient: 'linear-gradient(180deg, #C3D63E, #31CEDF)' },
        { height: 4000, gradient: 'linear-gradient(180deg, #31CEDF, #6fac29)' },
        { height: 4200, gradient: 'linear-gradient(180deg, #6fac29, #0c1d40)' },
        { height: 5000, gradient: 'linear-gradient(180deg, #3F1084, #3F1084)' },
      ];
      const [backgroundGradient, setBackgroundGradient] = useState(colorIntervals[0].gradient);
   
      useEffect(() => {
          const handleScroll = () => {
              const scrollPosition = window.scrollY;
              let newGradient = null;
              for (let i = colorIntervals.length - 1; i >= 0; i--) {
                  if (scrollPosition >= colorIntervals[i].height) {
                      newGradient = colorIntervals[i].gradient;
                      break;
                  }
              }
              if (newGradient && newGradient !== backgroundGradient) {
                  setBackgroundGradient(newGradient);
              }
          };
    
          window.addEventListener('scroll', handleScroll);
    
          return () => {
              window.removeEventListener('scroll', handleScroll);
          };
      }, [backgroundGradient]);
      const [bgColor, setBgColor] = useState('rgb(63, 16, 132)');

  const sections = [
    {
      id: 'section1',
      color: 'rgb(63, 16, 132)',
      content: (<section>
        <div
        className="revolution-slider-area slider-bg-1 slider-bg-2 rslide pb--60 home-margin position-relative privacy"
        style={backgroundImageStyle}
      >
        <div className="revolution-slider">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <div className="row pt--400 pb--60">
                    <div className="col-md-6 text-white title">
                      <h1 className="heading heading-h1 text-white font-120">
                       Privacy Policy
                        <h4 className="pt-3">
                        You are completely free to decide whether or not to visit our homepage. Our Conditions of Use and this notice shall govern any disagreement over privacy. Damage caps and mandatory arbitration of disputes are all part of this.
                        </h4>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <Slider/>
            </div>
          
        </div>
      </div>
      </section>

      ),
    },
    {
      id: 'section2',
      color: 'rgb(174, 40, 131)',
      content: (<section>
           <div className="pt--198"></div>
    <NewTitleDesc title="Privacy Policy" heading="Privacy Policy" subheading={<></>}  />
    
    <div className="row pt-4 m-0">
        <div className="col-md-12">
          <div className="container p-0 p-lg-auto">
            <div className="row mt-4 align-items-center">
              <div className="col-md-12 tab-heading">
                <h1 className="heading heading-h3 text-white"></h1>
                <h6 className="heading heading-h6 text-white letter-spacing-1 mb--15"></h6>
                <p className="text-white mb--10 privacy-para">In order to provide you with goods and services and protect ourselves and others from malicious activity, Fortunelogos collect personal data from the users of its sites. This information includes information our users give to us, information we collect as you use our sites as well as some information that is supplemented from third parties.</p>
                <p className="text-white mb--10 privacy-para">fortunelogos have implemented a number of measures to ensure your personal data remains safe as stored by us and when being transferred.</p>
                <p className="text-white mb--10 privacy-para">You may access your personal data, request a change or correction or request your data be deleted by contacting us via the channels described below. All requests will be facilitated to the amount reasonable and possible without breaching our data retention obligations.</p>
                <p className="text-white mb--10 privacy-para">You can also opt out of our direct marketing at any time by following the unsubscribe link from any of our marketing emails.</p>
                <h2 className="heading heading-h3 text-white">Introduction </h2>
                <p className="text-white mb--10 privacy-para">By using the site you consent to the collection, use and disclosure of your personal information in accordance with this policy.</p>
                <p className="text-white mb--10 privacy-para">Fortunelogos are committed to protecting your privacy. This privacy policy tells you about usage of our site, as well as, use and disclosure of personal information. The terms of this policy apply to all personal and business information collected by Fortunelogos on the fortunelogos.com and other Fortunelogos related procedures.</p>
                <p className="text-white mb--10 privacy-para">There are international standards relating to personal data processing and use, such as the General Data Protection Regulation (GDPR) and California Consumer Privacy Act (CCPA), to which Fortunelogos adheres.</p>
                {/* <p className="text-white mb--10 privacy-para">This site is operated by Fortunelogos Pty Ltd (Fortunelogos) trading as Fortunelogos. Any reference to Fortunelogos is, unless stated otherwise, to be read as a reference to Fortunelogos. Any reference to Fortunelogos in this Policy is assumed to apply to the Fortunelogos website and Design.com website.</p> */}
                <h2 className="heading heading-h3 text-white">The Information We Collect and Hold</h2>
                <p className="text-white mb--10 privacy-para">Personal data is any information that could directly or indirectly identify you. Under the GDPR, ‘personal data’ means any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
                <h3 className="heading heading-h3 text-white privacy-h3"style={{ fontSize: '1.5rem' }}>Information you provide </h3>
                <p className="text-white mb--10 privacy-para">You may provide us with personal data when you:</p>
                <ul className='text-white privacy-ul'>
                  <li>create an account </li>
                  <li>edit or add to personal data attached to your account details after your account has been created; or</li>
                  <li>indirectly create an account </li>
                  <li>request or purchase goods or services from us; or</li>
                  <li>contact us by any method, such as telephone, email, post, facsimile or in person; or</li>
                  <li>correspond via our site, either with one of our staff or with another site user</li>
                </ul>
                <p className="text-white mb--10 privacy-para ">Personal data you provide that we store may include, but not be limited to; your email address, your name, your country, your address, other contact details, your business name, your designer profile picture and description, the last four digits of your credit card and your credit card expiry date.</p>
                <p className="text-white mb--10 privacy-para">There may be additional information deemed personal data provided by you in briefs, on blogs or in comments or in interactions with other site users or otherwise while using our site, which is stored in our databases. This data may be monitored by us for trouble-shooting or maintenance purposes or if any form of abuse is suspected.</p>
                <h3 className="heading heading-h3 text-white privacy-h3">Information we collect</h3>
                
                <p className="text-white mb--10 privacy-para">Fortunelogos will collect your Internet Protocol (IP) address in order to perform certain fraud checks as well as ensuring you are served the correct tax information while using our site. Your IP address helps us identify the relevant tax jurisdiction.</p>
                <p className="text-white mb--10 privacy-para">Technologies such as “cookies” are used to allow the website to function, to collect useful information about visitors and to help make your user experience better. Cookies enable us to monitor traffic patterns and to serve you more efficiently if you revisit the site. A cookie does not identify you personally, but it does identify your computer. You can set your browser to notify you when you receive a cookie and this will provide you with an opportunity to either accept or reject each instance. We may allow some of our marketing or technology partners to utilise cookies on our website to collect non-personally identifying data to assist them, or Fortunelogos, in delivering services to you.</p>
                <p className="text-white mb--10 privacy-para">We also collect information about your usage of the site when you are logged in. This will include which pages you visit, products or services that are ordered, payments that are made etc. This activity data is stored in our databases.</p>
                <h3 className="heading heading-h3 text-white privacy-h3">Additional information provided by third parties</h3>
                <p className="text-white mb--10 privacy-para">Fortunelogos do receive some information from third parties, which is appended to the supplied and collected data. In order to use IP addresses effectively for fraud prevention purposes, location and other data related to the users IP address is received and stored. Further, in order to gain an additional data point for fraud checks as well as ensuring our marketing and customer service interactions are well received by our customers, we use third parties to append names to email addresses. This allows us to match names for fraud and deliver appropriately addressed correspondence.</p>
                <p className="text-white mb--10 privacy-para">When payments are processed by our payment processors, some information is provided back to Fortunelogos including a payment transaction reference number and any information relating to chargebacks and disputes. This information is critical to Fortunelogos being able to accurately record all financial data and deal with customer queries and disputes.</p>
                
                <h2 className="heading heading-h3 text-white">Data Security
                </h2>
                
                <p className="text-white mb--10 privacy-para">We take reasonable steps to protect the personal data we hold from misuse, interference or loss, and from unauthorised access, modification and disclosure. The steps we take to protect personal data include:</p>
                <ul className='text-white privacy-ul'>
                  <li>encrypting information sent between you and us using Secure Sockets Layer protocol (SSL) and 128-bit encryption (the highest level of encryption commercially available). Fortunelogos SSL certification is supplied by DigiCert (a US-based SSL Certificate Authority);</li>
                  <li>outsourcing transaction processing to reputable, secure, Payment Card Industry (PCI) compliant payment gateway providers (such as PayPal, Braintree, Stripe, and Payoneer). Fortunelogos does not store or handle complete credit card data. We only store the last four digits and expiry date;</li>
                  <li>utilising a fully secured technology stack on Amazon Web Services technologies. Fortunelogos databases are only accessible to certain IP addresses, locations and servers. In addition, Fortunelogos has implemented parts of the SANS security guidelines for securing its database servers;</li>
                  <li>limiting access to users' personal data through secure login to the Fortunelogos website.</li>
                 
                </ul>
                <h2 className="heading heading-h3 text-white">Data Storage and Transfer
                </h2>
                
                <p className="text-white mb--10 privacy-para">Fortunelogos stores your personal data on servers located in numerous countries including the United States and Australia.</p>
                
                
                <p className="text-white mb--10 privacy-para">When you visit our site you are agreeing to the transfer of data from the country you are in to Fortunelogossites across the world including the United States and Australia.</p>
                <h2 className="heading heading-h3 text-white ">Use of Your Personal Data

                </h2>
                <p className="text-white mb--10 privacy-para">Fortunelogos collect and use your personal information to:</p>
                <ol className='text-white privacy-ul'>
                  <li>create and manage your membership account(s);
                  </li>
                  <li>process your transactions</li>
                  <li>provide customer service, including reviewing or auditing data when interacting with a customer;</li>
                  <li>perform research and analysis aimed at improving our products, services and technologies;</li>
                  <li>display content that is customized to your interests and preferences;</li>
                  <li>run contests, select entrants, and choose prize winners;</li>
                  <li>run interactive areas</li>
                  <li>compensate you if you are a seller;</li>
                  <li>identify your uploaded content as your content if you are a seller;</li>
                  <li>communicate with you about your transactions, new products and services. We may send transaction-related communications such as welcome letters, billing reminders, and purchase confirmations. We may also send you surveys or marketing communications to inform you of new products or services or other information or offers that may be of interest. If you do not wish to receive surveys or marketing communications, you may adjust your preferences in the “My Account” section or follow the "unsubscribe" instructions included within each email communication. If you choose not to receive marketing communications, you will continue to receive transactional or account communications.</li>
                  <li>diagnose problems with our sites and services, including detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal activity, and prosecuting those responsible for that activity and debugging errors that impair functionality;</li>
                  <li>send notifications for special promotions or offers conducted by us, our suppliers or distributors</li>
                  <li>performing services such as maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, or providing similar services undertaking activities to verify or maintain the quality or safety of, or upgrade or enhance the Fortunelogos service</li>
                  <li>Personal information collected by Fortunelogos may be stored and processed in any country in which Fortunelogos is used and by providing us with your personal information and using our sites or services, you consent to any such transfer of information outside of your country. If you would like to exercise any of your personal information protection rights (including the right to have your personal information deleted), please contact us by email at dataprotection@Fortunelogos.com .</li>
                
                </ol>
                
                <h2 className="heading heading-h3 text-white">Disclosing Your Personal Data </h2>
                <p className="text-white mb--10 privacy-para">Except as set out in this privacy policy, Fortunelogos do not disclose or sell your personal information to any third-parties.</p>
                <p className="text-white mb--10 privacy-para">We may disclose your personal information to third-party service providers who perform services on our behalf such as to handle the processing of payments, to provide data storage, to host websites, to fulfill orders, to assist in marketing, to conduct audits, etc. Those third-party service providers (i) shall be permitted to obtain only the personal information they need to provide the service, (ii) must protect personal information to the same extent as Fortunelogos, and (iii) shall be prohibited from using it for any other purpose.</p>
                <p className="text-white mb--10 privacy-para">We may disclose your personal data to:</p>

                <ul className='text-white privacy-ul'>
                  <li>third party designers or clients in connection with any works (including without limitation artwork and designs) created or requested by you (as applicable) as part of the Fortunelogos Service</li>
                  <li>third parties that provide services to us, or functions on our behalf. Fortunelogos use third parties to provide various services that may involve storing or processing your personal information. These services include; data storage, payment processing, email distribution, email validation, SMS distribution, IP lookup, data enrichment and customer and relationship management systems;</li>
                  <li>related companies in our corporate group and business partners for the purpose of providing services which have been requested by you;</li> 
                  <li>information technology service providers responsible for our communication networks, software and system development and maintenance.</li>
                </ul>
                <p className="text-white mb--10 privacy-para">In these situations, we prohibit the third-party from using personal data about you except for the specific purpose for which we supply it.</p>
                <p className="text-white mb--10 privacy-para">In addition, we will not disclose your personal data without your consent unless legally required to do so, including but not limited to:</p>
                <ul className='text-white privacy-ul'>
                  <li>lessening or preventing a serious threat to life or health;</li>
                  <li>protecting the personal safety of users of this website or the public;</li>
                  <li>if we have reason to suspect that unlawful activity has been, is being or may be engaged in;</li>
                  <li>to enforce the law or necessary to investigate a suspected unlawful activity;</li>
                  <li>to exercise or defend legal claims; or</li>
                  <li>otherwise if authorised or required by law</li>
                </ul>
                <p className="text-white mb--10 privacy-para">Information about our customers, including personal information, may be disclosed as part of any merger, acquisition, or sale of the company and/or its assets, as well as in the unlikely event of insolvency, bankruptcy, or receivership, in which personal information would be transferred as one of the business assets of the company.</p>
                
                
                <h2 className="heading heading-h3 text-white">Access And Correction of Your Personal Data</h2>
                
                <p className="text-white mb--10 privacy-para">If you would like to review or revise information that you previously provided to us, you may access and change your personal information in the "My Account" section of the site. You may also contact Customer Service by email at support@Fortunelogos.com  for assistance.</p>
                <p className="text-white mb--10 privacy-para">We will, on request, provide you with access to the personal data we hold about you, unless there is an exception which applies. If we refuse to provide you with access to the information, we will provide you with reasons for the refusal.</p>
                <p className="text-white mb--10 privacy-para">You may request updates to your personal data, which we will endeavour to accommodate in ways that do not breach our legal record keeping requirements. The easiest and quickest way to add, edit or update most of your personal data is to log into your Fortunelogos account and make the changes yourself.</p>
                <p className="text-white mb--10 privacy-para">You may request that we delete your personal data, which we will make reasonable endeavours to accommodate. There may be legitimate reasons why we cannot facilitate the deletion of personal data, including where a legal and commercial relationship has been established or where data retention regulations, such as tax records, require this retention.</p>
                <p className="text-white mb--10 privacy-para">If you wish to request a copy of your personal data, make edits to your personal data or request your personal data be deleted, please email your request to dataprotection@Fortunelogos.com . All requests via this channel will be responded to or actioned within one month of receipt.</p>
                <p className="text-white mb--10 privacy-para">Fortunelogos will not discriminate against any customer who makes a request under the CCPA. Fortunelogos do not sell personal data so we do not currently provide a self-service option to opt-out of selling personal information. If Fortunelogos were ever to sell personal information we will notify existing customers and offer this functionality on-site.</p>
                <p className="text-white mb--10 privacy-para">Please note; you may opt out of direct marketing at any time by using the unsubscribe link at the foot of all Fortunelogos marketing emails.</p>
                
                <h2 className='heading heading-h3 text-white'>Change of Control</h2>
                <p className="text-white mb--10 privacy-para">In future, Fortunelogos may sell, divest or otherwise change ownership of all or part of its business. The data collected and stored by Fortunelogos could be a part of the assets involved in that change of control. If any future change of ownership is likely to have a material impact on you, with regard to your personal data, then you will be notified of the impending change via email and/or a prominent notice on our site.</p>
                <h2 className='heading heading-h3 text-white'>Other</h2>
                <h3 className='heading heading-h3 text-white privacy-h3'>Children</h3>
                <p className="text-white mb--10 privacy-para">We do not intend to solicit or collect personal information from anyone under the age of 18 without parental consent. If you are under 18, you shall not use or enter information on this site, including without limitation, any Interactive Areas of the site without parental consent. Please contact Customer Services by email at support@Fortunelogos.com   to obtain the necessary parental consent forms.</p>
                <h3 className='heading heading-h3 text-white privacy-h3'>Links</h3>
                
                <p className="text-white mb--10 privacy-para">We may link to websites, including third-party content providers, that have different privacy policies and practices from those disclosed here. We assume no responsibility for the policies or practices of such linked sites and encourage you to become acquainted with them prior to use.</p>
                
                <h2 className='heading heading-h3 text-white'>Changes to This Privacy Policy</h2>
                
                <p className="text-white mb--10 privacy-para">We may modify or amend this Privacy Policy as our business requirements or the law changes. Any changes to this Privacy Policy will be updated on the website and become effective when published, so please visit the website periodically to ensure that you have our most current privacy policy.</p>
                <h2 className='heading heading-h3 text-white'>Contact Details</h2>
                <p className="text-white mb--10 privacy-para">If you have any questions about our Privacy Policy, have a complaint about a breach of your privacy or if you have any query on how your personal data is collected or used, please email your request to dataprotection@Fortunelogos.com .</p>
                <p className="text-white mb--10 privacy-para">If you wish to request a copy of your personal data, make edits to your personal data or request your personal data be deleted, please email your request to dataprotection@Fortunelogos.com . All requests via this channel will be responded to or actioned within one month of receipt.</p>
                <p className="text-white mb--10 privacy-para">We are not required to have a Data Protection Officer so any enquiries about our use of your personal data should be sent to the email address provided above.</p>
                <p className="text-white mb--10 privacy-para">If you wish to make a complaint about an alleged breach of the Privacy Act or other privacy regulations and legislation, we will ask you to send us your complaint in writing to either of the email address provided above. We will respond to complaints within a reasonable period (usually 30 days)</p>
                
                
                
                {/* <div className="d-flex align-items-center">
                  <div className="view-portfolio">
                    <button><a href="">Get a Custom Logo</a></button>
                  </div>
                  <div className="trans-btn">
                    <button onClick={openLiveChat}><i className="fa-regular fa-comment"></i> Live Chat</button>
                  </div>
                </div> */}
              </div>
              {/* <div className="col-md-6 pt-lg-0 pt-md-0 pt-4">
                <img src={cltimgtab} alt="" />
              </div> */}
            </div>
          </div>
            </div>
          </div>
      </section>

      ),
    },
    // {
    //   id: 'section3',
    //   color: 'rgb(240, 88, 116)',
    //   content: (
    //     <section>
         
    //      <div className="container">
    //         <div className="row mt-4 align-items-center flex-md-row flex-lg-row flex-column-reverse">
    //           <div className="col-md-6">
    //             <img src={t2img} alt="" />
    //           </div>
    //           <div className="col-md-6 tab-heading">
    //             <h1 className="heading heading-h3 text-white">Exceptional Web Development</h1>
    //             <h6 className="heading heading-h6 text-white letter-spacing-1 mb--15">Fortified Backend + Seamless & Attractive Frontend = A Dynamic Website.</h6>
    //             <p className="text-white mb--10">Your website experience offers a preview of your services/products, making the need for a dynamic website more important than ever for your brand. Fortune Logos’s web development services craft seamless websites fueled with our designers’ passion and expertise. Our web development team employs unique and accessibility-friendly web layouts, custom designs, and strong coding to deliver a website you’d be proud to call yours. From executing seamless user interfaces to captivating visuals, crafting simplified designs to enriching user experiences, our each web development technique works in hand to attract and convert more customers for your brand. Our customers see a noticeable boost in their conversion rates and a drop in their bounce rates with our deployed websites. And no, our work doesn't stop until they do. Let’s get started with our exceptional web development to get a seamless and dynamic website to represent your brand</p>
               
    //             <div className="d-flex align-items-center">
    //               <div className="view-portfolio">
    //                 <button><a href="">Get My Website</a></button>
    //               </div>
    //               <div className="trans-btn">
    //                 <button onClick={openLiveChat}><i className="fa-regular fa-comment"></i> Live Chat</button>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="container">
    //         <div className="row mt-4 align-items-center">
    //           <div className="col-md-6 tab-heading">
    //             <h1 className="heading heading-h3 text-white">Engaging Video Animation</h1>
    //             <h6 className="heading heading-h6 text-white letter-spacing-1 mb--15">Harness the Power of Captivating Visual Storytelling.</h6>
    //             <p className="text-white mb--10">At Fortune Logos, we are masters of creativity and art, and the core of our work lies in leveraging the power of imagery to attract, engage, and grow. When it comes to video animation, we tackle each project with a balance between communication and creativity. Our team of expert video animators and designers use their invaluable creative skills and strategies to craft engaging and attractive videos that communicate your brand’s essence and what it wants its customers to know. The quality of our animations speaks to our reputation in the video animation world, and we offer to bring your brand the same in its industry. Creativity should know no bounds, and we stand by that. We combine creativity with succinct storytelling to create interactive and attractive videos that are uniquely yours. Let’s combine our creativity to craft a captivating video to attract and engage your audience.</p>

    //             <div className="d-flex align-items-center">
    //               <div className="view-portfolio">
    //                 <button><a href="">Lets Get Started</a></button>
    //               </div>
    //               <div className="trans-btn">
    //                 <button  onClick={openLiveChat}><i className="fa-regular fa-comment"></i> Live Chat</button>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="col-md-6 pt-lg-0 pt-md-0 pt-4">
    //             <img src={t3img} alt="" />
    //           </div>
    //         </div>
    //       </div>

    //       <div className="container">
    //         <div className="row mt-4 align-items-center flex-md-row flex-lg-row flex-column-reverse">
    //           <div className="col-md-6">
    //             <img src={t4img} alt="" />
    //           </div>
    //           <div className="col-md-6 tab-heading">
    //             <h1 className="heading heading-h3 text-white">Targeted Digital Marketing</h1>
    //             <h6 className="heading heading-h6 text-white letter-spacing-1 mb--15">The Path to Online Success—Data-Driven Digital Marketing.</h6>
    //             <p className="text-white mb--10">The landscape of competition is fierce, especially if you want your brand to survive and begin climbing the stairs of revenue and success. Fortune Logos’s team of digital marketing specialists have a track record of highly successful marketing campaigns and a plethora of experience and industry insights under their belt to help you outshine your competition. PPC, SEO optimization, SMM, content marketing, or more, you name your ideal growth expectations and we can deliver the results with the right campaigns and strategies. Whether it's satisfying the search algorithms or creating a strong social media presence, you can rest easy knowing your business's growth is being fueled by our meticulous strategy development to steer your business toward elevated success. Let’s work together to begin your journey toward online success with our data-driven and targeted digital marketing provided by industry experts.</p>
    //             <div className="d-flex align-items-center">
    //               <div className="view-portfolio">
    //                 <button><a href="">Discuss Now</a></button>
    //               </div>
    //               <div className="trans-btn">
    //                 <button  onClick={openLiveChat}><i className="fa-regular fa-comment"></i> Live Chat</button>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
        
    //     </section>

    //   ),
    // },
    // {
    //   id: 'section4',
    //   color: 'rgb(255, 91, 81)',
    //   content: (
    //     <section>
    //      <div className="workflow1 d-lg-block d-none">
    // <div className="brook-about-area brook-about-area pt--60 bg-shape pt-5 pb-5">
    //   <div className="container-fluid">
    //     <div className="row">
    //       <div className="col-lg-12">
    //         <div className="text-center">
    //           <h2 className="heading-title text-white">Work Flow</h2>
    //           <h1 className="heading heading-h3 text-white rslide">Our 360 Degree <br />Creative Process</h1>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="container-fluid pt-md-4">
    //                         <div className="row pt-3 justify-content-center">
    //                             <div className="col-md-9 text-center">
    //                                 <img src={workflow} alt="" className="img-fluid w-100" />
    //                             </div>
    //                         </div>
                       
                   
    //             </div>
    //             </div>

    //   </div>
    //   </div>

    //     </section>

    //   ),
    // },
    // {
    //   id: 'section5',
    //   color: 'rgb(111, 172, 41)',
    //   content: (
    //     <section>
    //      <div className="workflow1 d-lg-block d-none">
    // <div className="brook-about-area brook-about-area pt--60 bg-shape pt-5 pb-5">
    //   <div className="container-fluid">
    //     <div className="row">
    //       <div className="col-lg-12">
    //         <div className="text-center">
    //           <h2 className="heading-title text-white">Work Flow</h2>
    //           <h1 className="heading heading-h3 text-white rslide">Our 360 Degree <br />Creative Process</h1>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="container-fluid pt-md-4">
    //                         <div className="row pt-3 justify-content-center">
    //                             <div className="col-md-9 text-center">
    //                                 <img src={workflow} alt="" className="img-fluid w-100" />
    //                             </div>
    //                         </div>
                       
                   
    //             </div>
    //             </div>

    //   </div>
    //   </div>

    //     </section>

    //   ),
    // },
    // {
    //   id: 'section6',
    //   color: '#00CE7D',
    //   content: (
    //     <section>
    //     <div className="container-fluid pb-4">
    //   <div className="row">
    //     <div className="col-lg-12">
    //       <div className="text-center">
    //         <h2 className="heading-title text-white">TESTINOMIALS</h2>
    //         <h1 className="heading heading-h3 text-white rslide">Boost Your Brand Awareness with a Quick Call to Us</h1>
    //       </div>
    //     </div>
    //   </div>
    //   <TestimonialSlider testimonials={testimonials}/>
             
    //       </div>
    //   </section>
    //   ),
    // },
  ];

  useEffect(() => {
    const handleScroll = () => {
        const scrollPosition = window.scrollY + (window.innerHeight * 0.75);

        sections.forEach(section => {
            const element = document.getElementById(section.id);
            if (element && element.offsetTop <= scrollPosition) {
                setBgColor(section.color);
            }
        });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [sections]);  
  return (
    <>
    <MetaTitleDesc metatitle={"Privacy Policy - FortuneLogos"} metadesc={"We are a team of experienced logo designers in New York with extensive experience producing logos, branding, site design, and video animation for all of our clients in the United States."} />
      <div style={{ background:bgColor,minHeight: '100vh', transition: 'background 0.3s ease' }}>
      <div className="rvbody">


        {sections.map(section => (
          <Element key={section.id}  id={section.id} className="rslide">
            {section.content}
          </Element>
        ))}


      </div>

    </div>
              
    </>
  )
}

export default Privacy
